<template>
  <main class="sub __contact __shop">
    <div class="inner shop">
      <div>
        <img src="~assets/image/main/ShopIntrodImg.png" alt="" />
        <div class="Introd-Wrap">
          <p class="IntrodTitle">개인정보처리방침</p>
          <span
            >투게더웰㈜('https://hogoo.togetherwel.co.kr'이하 '호구153 제휴기업
            복지몰')은(는) 개인정보를 다음 항목의 개인정보를 수집합니다.<br />
            - 휴대전화번호, 자택주소, 이름, 포인트</span
          >
        </div>
        <div class="Introd-Wrap">
          <p class="IntrodTitle">회원가입 및 이용안내</p>
          <span
            >호구153 구성원의 삶과 사역의 도움을 주고자 폐쇄형 온라인 마켓인
            '투게더웰(호구153 제휴기업 복지몰)' 이용에 관한 안내입니다.<br />
            투게더웰(호구153 제휴기업 복지몰)의 서비스를 이용하는 것으로
            호구153이 직접 운영 및 관리하는 복지몰은 아닙니다.</span
          >
        </div>
        <div class="btn-wrap">
          <a
            class="btn-save01"
            href="javascript:void(0);"
            @click="sendPostShoppingMall"
            @touchend="sendPostShoppingMall"
            style="width: 250px"
            >복지몰 바로가기
            <img
              alt=""
              src="https://admin.hogoo153.com/common/image?fileNo=453"
            />
          </a>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import { getShopUserInfo } from 'Api/modules'
export default {
  components: {},
  mixins: [],
  data() {
    return {
      userNm: '',
      phone: '',
    }
  },
  computed: {},
  methods: {
    async sendPostShoppingMall() {
      console.log('sendPostShoppingMall')
      const shoppingMallSSOUrl = 'https://cert.benecafe.co.kr/sso/loginSsoGate'
      const shoppingMallForm = document.createElement('form')
      shoppingMallForm.method = 'POST'

      // 회원사고객 아이디
      const memID = document.createElement('input')
      memID.type = 'hidden'
      memID.name = 'memID'
      memID.value = this.phone
      shoppingMallForm.appendChild(memID)

      // 고객사코드
      const cmpyNo = document.createElement('input')
      cmpyNo.type = 'hidden'
      cmpyNo.name = 'cmpyNo'
      cmpyNo.value = 'AWB'
      shoppingMallForm.appendChild(cmpyNo)

      // 회원명
      const mbrNm = document.createElement('input')
      mbrNm.type = 'hidden'
      mbrNm.name = 'mbrNm'
      mbrNm.value = this.userNm
      shoppingMallForm.appendChild(mbrNm)

      document.body.appendChild(shoppingMallForm)

      shoppingMallForm.target = 'benecafeWindow'
      // window.open(
      //   '',
      //   'benecafeWindow',
      //   'width=1920, height=1080, resizable=0, scrollbars=no, status=1, titlebar=1, toolbar=1, left=100, top=100',
      // )
      // window.open('benecafeWindow', '_blank')
      shoppingMallForm.action = shoppingMallSSOUrl
      shoppingMallForm.submit()
    },
    async fetchShopUserInfo() {
      const res = await getShopUserInfo()

      this.phone = res.memID

      this.userNm = res.mbrNm
    },
  },
  async created() {
    await this.fetchShopUserInfo()
    this.$nextTick(() => {
      this.sendPostShoppingMall()
    })
  },
}
</script>
